@use '../styles/tokens/common/scss/variables' as common;

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin color-variant($bg-color: null, $bd-color: null, $color: null) {
  background-color: $bg-color;
  border-color: $bd-color;
  color: $color;
}

@mixin bg-css-prop(
  $bg: transparent,
  $opacity: 0,
  $transition: none,
  $parent-overflow: hidden,
  $border-radius: common.$border-radius-2
) {
  overflow: $parent-overflow;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    opacity: $opacity;
    background-color: $bg;
    transition: $transition;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    border-radius: $border-radius;
  }
}

@mixin bg-css-prop-transition($bg: transparent, $opacity: 0, $transition: none) {
  &::before {
    opacity: $opacity;
    background-color: $bg;
    transition: $transition;
  }
}

@mixin cell-marker {
  position: relative;

  &::after {
    content: '';
    @include position(absolute, 0, 0);
    width: 0;
    height: 0;
    display: block;
    border-left: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-top-width: 8px;
    border-top-style: solid;
  }
}

@mixin cell-marker-color($color: null) {
  &::after {
    border-top-color: $color;
  }
}

@mixin cell-with-marker {
  overflow: visible;
  @include cell-marker;
}

@mixin cell-with-marker-color($bg-color, $marker-color: null) {
  background-color: $bg-color;
  @include cell-marker-color($marker-color);
}

// Use this mixin to add styles for specific brand or theme. Possible arguments: 'snp', 'light', 'bny-light', etc.
@mixin branded-styles-for($brand) {
  ::ng-deep [class*='#{$brand}'],
  ::ng-deep [data-branding*='#{$brand}'] {
    @content;
  }
}

@mixin mobile-phones-only {
  @media screen and (max-width: (common.$grid-breakpoint-sm - 1px)) {
    @content;
  }
}

@mixin mobile-and-tablet {
  @media screen and (max-width: (common.$grid-breakpoint-md - 1px)) {
    @content;
  }
}

@mixin tablet-portrait-only {
  @media screen and (min-width: common.$grid-breakpoint-sm) and (max-width: common.$grid-breakpoint-lg) and (orientation: portrait) {
    @content;
  }
}

@mixin desktops-only {
  @media (hover: hover) and (pointer: fine) and (min-width: common.$grid-breakpoint-sm) {
    @content;
  }
}

@mixin touchscreens-only {
  @media (pointer: coarse) {
    @content;
  }
}

@mixin large-card-title-spacing {
  padding-right: 1rem;

  @media (min-width: common.$grid-breakpoint-sm) {
    padding-right: 1.5rem;
  }
}

@mixin enable-important($property, $value) {
  @if ($enable-important-utilities == true) {
    #{$property}: $value !important;
  } @else {
    #{$property}: $value;
  }
}

@mixin safari-only {
  @supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
    @content;
  }
}
